<template>
  <div class="px-2">
    <div style="height: 82px;" />
    <b-card title="Welcome 🚀">
      <b-card-text>Welcome to Toqoo Material.</b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  title() {
    return 'Welcome 🚀'
  },
  components: {
    BCard,
    BCardText,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      data: Object,
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>
